import {AppBar, Box, Button, Container, Icon, IconButton, Toolbar, Tooltip, Typography, useTheme} from "@mui/material";
import React from "react";
import Brightness7Icon from '@mui/icons-material/Brightness7'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import {ColorModeContext} from "../App";
import {Facebook, YouTube} from "@mui/icons-material";

const NavigationBar = () => {
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext)
    return (
        <Box sx={{flexGrow:1}}>
            <AppBar component={"nav"}>
                <Toolbar>
                    <Typography variant="h6"
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    mr: 2,
                                    textAlign: "left",
                                    flexGrow: 1,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none'
                                }}>
                        K2B2 Records
                    </Typography>
                    <Box sx={{textAlign: "right"}}>
                        <Button href={"#Catalog"} sx={{ my: 2, display: 'block', color: 'white'}}>Catalog</Button>
                    </Box>
                    <Box sx={{paddingRight: 12}}>
                        <Tooltip title={"Toggle Dark Mode"}>
                        <IconButton
                            size={"large"}
                            aria-label={"Toggle Dark Mode"}
                            sx={{ml:1}}
                            onClick={colorMode.toggleColorMode}
                            color={"inherit"}
                        >
                            {theme.palette.mode === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
                        </IconButton>
                        </Tooltip>
                        <Tooltip title={"Find us on Facebook"}>
                            <IconButton href={"https://www.facebook.com/k2b2records/"} target={"_blank"}>
                                <Facebook/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Find us on YouTube"}>
                            <IconButton href={"https://www.youtube.com/@subtoner/videos"} target={"_blank"}>
                                <YouTube/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    )
}


export default NavigationBar