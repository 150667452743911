import React, {ReactNode} from "react"
import {Container, Divider, Typography} from "@mui/material";
import {K2B2AlbumListView, VivaceAlbumListView} from "./AlbumListView";
import {DividerMedium} from "../components/DividerMedium";

type Props = {
    children: ReactNode
}
const Catalog = ({children} : Props) => {
    return (
        <Container>
            {children}
        </Container>
    )
}

export default Catalog

export const K2B2Catalog = () => {
    return(
        <Catalog>
            <K2B2AlbumListView/>
        </Catalog>
    )
}

export const VivaceCatalog = () => {
    return(
        <Catalog>
            <VivaceAlbumListView/>
        </Catalog>
    )
}